<template>
  <header-line>
    <template v-slot:right></template>
  </header-line>
  <div class="content content--center">
    <section class="page-404">
      <figure class="page-404__image">
        <img src="../img/404.svg" alt="404">
      </figure>
      <div class="page-404__text">
        <p>{{ t('message.not_found') }}</p>
      </div>
      <div class="page-404__btns">
        <router-link v-bind:to="{name: 'home', params: {lang}}"
                     class="btns btn-blue"
        >
          {{ t('label.return_home') }}
        </router-link>
      </div>
    </section>
  </div>

</template>

<script>
import {useI18n} from 'vue-i18n'
import HeaderLine from "@/components/HeaderLine";

export default {
  name: 'not_found',
  components: {HeaderLine},
  setup() {
    const i18n = useI18n();

    return {t: i18n.t};
  }
}
</script>

<style lang="scss">
.page-404 {
  text-align: center;

  &__image {
    margin-bottom: 8px;
  }

  &__text {
    margin: 0 auto;
    max-width: 495px;

    p {
      margin-bottom: 12px;
      font-size: 32px;
      font-weight: 300;
      line-height: 1.13;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  &__btns {
    padding-top: 12px;
  }
}

</style>
